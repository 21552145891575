
<template>
    <router-view />
</template>

<script>
export default {
  name: 'Public',
}
</script>
